<template>
  <section class="min-w1600">
    <!-- <div class="searchBar line"></div> -->
    <div class="searchwrap">
       <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.date') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs"
                          :isOldYn="true"
                          @setOldYn="setOldYn" :oldYn="reqData.oldYn"/>
         </div>
       </div>
       <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('common.stock') }}</span></div>
           <select v-model="reqData.branchID">
             <option v-for="item in branchIDList" :value="item.value" :key="item.value">
               {{ item.text }}
             </option>
           </select>
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.sportb') }}</span></div>
           <select>
              <option value="">{{ $t('table.head.sportAll') }}</option>
           </select>
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.teamName') }}</span></div>
           <input type="text" />
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('common.sort') }}</span></div>
           <select>
             <option value="">{{ $t('searchArea.matchFirst') }}</option>
             <option value="">{{ $t('searchArea.matchLast') }}</option>
             <option value="">{{ $t('searchArea.matchHigh') }}</option>
           </select>
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.memberID') }}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.memId" />
             <button class="btn-search" type="button" @click="getSportEventList(1)">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
       </div>
       <memo></memo>
    </div>
    <!--div class="tab-wrap">
      <select v-model="currentPage">
        <option :value="'all'">전체보기</option>
        <template v-for="item in tabList" :key="item.code">
          <option :value="item.code">{{ item.codeName }}</option>
        </template>
      </select>
    </div-->
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <span class="title">{{ $t('table.head.betAmt') }}</span> : <span class="value">{{ numberWithCommas(totalbetAmt) }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t('table.head.exWinAmt') }}</span> : <span class="value">{{ numberWithCommas(totalGainAmt) }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t('table.head.winAmt') }}</span> : <span class="value">-</span>
          </div>
        </div>
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img src="@/assets/img/searchicon.png" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t('searchArea.schMatch') }}</span> : <span class="value">{{ numberWithCommas(betList.length) }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <colgroup>
            <col style="width: 5%;"/>
            <col style="width: 10%;"/>
            <col style="width: 15%;"/>
            <col style="width: 15%;"/>
            <col style="width: 15%;"/>
            <col style="width: 5%;"/>
            <col style="width: 15%;"/>
            <col style="width: 5%;"/>
            <col style="width: 5%;"/>
            <col style="width: 10%;"/>
          </colgroup>
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-if="betList.length > 0">
              <template v-for="item in betList" :key="item.newEventID">
                <tr>
                  <td>
                    <div class="fx-center-all">
                      <em class="type volley"></em>
                      {{ SPORT_BRANCH_LIST[item.branchID] }}
                    </div>
                  </td>
                  <td><input type="text" name="" :value="replaceDateT(item.eventDate)" readonly/></td>
                  <td>
                    <div class="fx-center-all gap-10">
                      <div class="fx-center-col">
                        <span>{{ item.leagueNameKO || item.leagueName }}</span>
                        <!--date-selectors :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" /-->
                      </div>
                    </div>
                  </td>
                  <td class="bg-gr">
                    <span>{{ item.homeTeamKO || item.homeTeam }}</span>
                  </td>
                  <!--td>
                     <a @click="active=!active" class="b-sports" :class="active ? 'btn-on' : 'btn-off'">{{ active ? "베팅가능" : "베팅불가" }} </a>
                  </td-->
                  <!--td>
                     <a @click="active=!active" class="b-sports" :class="active ? 'btn-on' : 'btn-off'">{{ active ? "베팅가능" : "베팅불가" }} </a>
                  </td-->
                  <td class="bg-gr">
                    <span>{{ item.awayTeamKO || item.awayTeam }}</span>
                  </td>
                  <td class="bg-gr">
                    <a class="btn-list b-sports" @click="openWindow(item)">{{ $t('table.head.betList') }}</a>
                    <!--div class="fx-center-all gap-5">
                       <a class="btn-list b-sports">▲</a>
                       <a class="btn-list b-sports">▼</a>
                    </div-->
                  </td>
                  <td class="bg-gr">
                    <div class="fx-center-col gap-5">
                      <em class="fc-blue">{{ thousand(item.totalbetAmt) }}</em>
                      <em class="fc-red">{{ thousand(item.totalGainAmt) }}</em>
                    </div>
                  </td>
                  <td>{{ item.lineTypeName }}</td>
                  <td>
                    <span> - </span>
                    <!--a @click="active=!active" class="b-sports" :class="active ? 'btn-on' : 'btn-off'">{{ active ? "on" : "off" }} </a-->
                  </td>
                  <td>-</td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <td colspan="10">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="betList.length !== 0" @goPage="goPage" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import DateSelectors from '@/components/common/DateSelector_day'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { betList, getCode } from '@/api/member.js'
import { GAME_INFO_LIST, GAME_CODE_NAME, SPORT_BRANCH_LIST } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr, displayDateTime, thousand } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import { mapState } from 'vuex'
import { sportEventList } from '@/api/bet'
import Memo from '@/components/common/memo'

export default {
  name: 'BetInfo',
  components: {
    SearchFilter,
    DateSelector,
    DateSelectors,
    TableHead,
    UiCheck,
    subTitle,
    Pagination,
    Memo
  },
  data: function () {
    return {
      active: false,
      tableData: {},
      headInfo: {
        fstColumn: false,
        dataList: ['sporta', 'gametime', 'sportb', 'sportc', 'sportg', 'empty', 'betwin', 'searchType', 'sporti', 'sportj']
      },
      order: '',
      reqData: {
        page: 1,
        count_per_list: 100,
        siteId: '',
        memId: '',
        branchID: null,
        orderColumn: null,
        orderType: null,
        startDate: null,
        endDate: null,
        oldYn: 'N'
      },
      pageInfo: {},
      betList: [],
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      branchIDList: [],
      totalbetAmt: 0,
      totalGainAmt: 0
    }
  },
  methods: {
    numberWithCommas,
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    displayDateTime,
    replaceDateT,
    thousand,
    sortBetAmt (order) {
      this.order = `betAmt ${order}`
      this.getSportEventList(1)
    },
    sortWinAmt (order) {
      this.order = `betWinAmt ${order}`
      this.getSportEventList(1)
    },
    sortBetTime (order) {
      this.order = `betTime ${order}`
      this.getSportEventList(1)
    },
    sortEventTime (order) {
      this.order = `eventTime ${order}`
      this.getBetList(1)
    },
    shouldApplyClass (item) {
      return item.length > 1
    },
    calOdds (item) {
      const totalodd = item.reduce((accumulator, data) => {
        return accumulator * data.lineOdds
      }, 1)

      const roundedTotalOdd = Math.floor(totalodd * 100) / 100

      return roundedTotalOdd.toFixed(2)
    },
    exCalOdds (item) {
      const odds = this.calOdds(item)
      const extotalodd = item.reduce((accumulator, data) => {
        return odds * data.betAmt
      }, 1)
      return extotalodd.toFixed(0)
    },
    openWindow (item) {
      const query = `?siteId=${item.siteId}&lineTypeID=${item.lineTypeID}&newMasterEventID=${item.newMasterEventID}&newEventID=${item.newEventID}&oldYn=${this.reqData.oldYn}`
      window.open('/sport/eventDetailList' + query, 'sportList', 'top=0,left=150,scrollbars=no,resizable=no,width=1900,height=885,status=no')
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async getSportEventList (pageNum) {
      this.emitter.emit('Loading', true)
      this.reqData.siteId = this.siteId
      if (pageNum) {
        this.reqData.page = pageNum
      } else {
        this.reqData.page = 1
      }

      const data = this.reqData
      if (this.order !== '') {
        const tempOrder = this.order.split(' ')
        const orderColumn = tempOrder[0]
        const orderType = tempOrder[1]

        data.orderColumn = orderColumn
        data.orderType = orderType
      } else {
        data.orderColumn = ''
        data.orderType = ''
      }

      console.log('list params : ', data)
      sportEventList(data).then(res => {
        this.betList = []
        if (res.resultCode === '0') {
          const list = res.data.list
          list.forEach(item => {
            this.totalbetAmt += item.totalbetAmt
            this.totalGainAmt += item.totalGainAmt
          })

          console.log('betInfo List: ', res)

          this.betList = list
        }
        this.emitter.emit('Loading', false)
      })
    }
  },
  computed: {
    SPORT_BRANCH_LIST () {
      return SPORT_BRANCH_LIST
    },
    STATUS_LIST () {
      return STATUS_LIST
    },
    ...mapState([
      'siteOption'
    ]),
    siteId () {
      return this.siteOption.siteId
    }
  },
  async created () {
    this.branchIDList.push({
      value: null,
      text: '전체'
    })
    for (const key in SPORT_BRANCH_LIST) {
      this.branchIDList.push({
        value: key,
        text: SPORT_BRANCH_LIST[key]
      })
    }
    this.setStartDate()
    this.setEndDate()
    await this.getSportEventList()
  }
}
</script>

<style scoped>
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.b-sports {display: flex;align-items: center;justify-content: center;border-radius: 3px;padding: 3px;cursor: pointer;width: 90px;margin: 0 auto;}
.bg-gr {background: #f9f8f1;}
.type {width: 20px;height: 20px;display: flex;margin-right: 5px;}
.soccer {background: url(~@/assets/img/icon_soccer.png) center left no-repeat;}
.volley {background: url(~@/assets/img/icon_volleyball.png) center left no-repeat;}
.basket {background: url(~@/assets/img/icon_basketball.png) center left no-repeat;}
.base {background: url(~@/assets/img/icon_baseball.png) center left no-repeat;}
.ussoccer {background: url(~@/assets/img/icon_ussoccer.png) center left no-repeat;}
.haki {background: url(~@/assets/img/icon_haki.png) center left no-repeat;}
</style>
